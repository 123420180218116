import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { FixedAttributionIcon } from "fixed-attribution-icon";
new FixedAttributionIcon(
  "https://github.com/michaelgudzevskyi/3d-react-model-configurator"
);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
